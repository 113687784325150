/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Styled } from "theme-ui";
import { graphql } from "gatsby";

import RecipeCard from "../components/RecipeCard";

const gridParentCSS = length => css`
  padding-top: 200px;
  clear: both;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-auto-rows: 200px;
  grid-gap: 1rem;
  padding-top: 40px;

  @media (min-width: 520px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 160px;
  }
`;

const RecipeGrid = ({ recipes = [] }) => {
  return (
    <Styled.div css={gridParentCSS(recipes.length)}>
      {recipes.length > 0 &&
        recipes.map(({ frontmatter: { title, slug, image } }) => {
          return (
            <RecipeCard
              key={slug}
              recipe={{ title, slug, image }}
            />
          );
        })}
    </Styled.div>
  );
};

export default RecipeGrid;

export const query = graphql`
  fragment IndexRecipeFragment on Mdx {
    frontmatter {
      title
      slug
      image {
        sharp: childImageSharp {
          fluid(quality: 75, maxWidth: 960) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
